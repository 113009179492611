<template>
    <div class="jmsb-annonce-photo-container">
      <h2 class="is-uppercase is-size-4" v-if="hideForModal">Photos</h2>
      
      <!-- BEGIN FORM -->
      
      <!-- BEGIN SECTION 1 -->
      <div class="">
        <div class="columns  jsmb-annonce-photo">
          <div class="column is-12">
            <div style="display:flex;margin-bottom:1rem">
              <span class="icon has-text-info">
                <i class="fas fa-info-circle"></i>
              </span>
              <strong>Les photos correspondent à votre annonce et non spécifiquement à vos places.</strong>
            </div>
            
            <p>Cliquez <strong>"Choisir un fichier"</strong> pour télécharger<br>votre image (PNG, JPG, JPEG)</p>
          </div>
        </div>


        <div class="columns  jsmb-annonce-pic">

          <div class="column has-text-centered">
            <h3 class="has-text-left">Accès</h3>
            <div class="add-margin-bottom-1" :class="stepThree.file_1.object === null ? notError.file_1 ? 'jsmb-annonce-pic-isNotFilled' : 'jsmb-annonce-pic-isNotFilled red' : 'jsmb-annonce-pic-isFilled'">
              <figure class="image">
                <img :src="stepThree.file_1.view">
              </figure>
              
              <b-field class="file">
                <b-upload drag-drop v-model="stepThree.file_1.object" @input="btnUpload('file-1')">
                  <a class="button is-small is-primary">
                    <span>Choisir un fichier</span>
                  </a>
                </b-upload>
              </b-field>
            </div>
            <p class="help has-text-weight-bold has-text-danger has-text-centered has-margin-bottom-1" :class="{'is-hidden': notError.file_1}">{{errorMsg.file_1}}</p>
          </div>

          <div class="column has-text-centered">
            <h3 class="has-text-left">Espace</h3>
            <div class="add-margin-bottom-1" :class="stepThree.file_2.object === null ? notError.file_2 ? 'jsmb-annonce-pic-isNotFilled' : 'jsmb-annonce-pic-isNotFilled red' : 'jsmb-annonce-pic-isFilled'">
              <figure class="image">
                <img :src="stepThree.file_2.view">
              </figure>
              <b-field class="file">
                <b-upload drag-drop v-model="stepThree.file_2.object" @input="btnUpload('file-2')">
                  <a class="button is-small is-primary">
                    <span>Choisir un fichier</span>
                  </a>
                </b-upload>
                <a class="button is-danger" v-if="!hideForModal && stepThree.file_2.btnRemove" @click="removePiture('file_2')"><span>Supprimer</span></a>
              </b-field>
            </div>
            <p class="help has-text-weight-bold has-text-danger has-text-centered has-margin-bottom-1" :class="{'is-hidden': notError.file_2}">{{errorMsg.file_2}}</p>

          </div>

          <div class="column has-text-centered">
            <h3 class="has-text-left">Autres</h3>
            <div class="add-margin-bottom-1" :class="stepThree.file_3.object === null ? notError.file_3 ? 'jsmb-annonce-pic-isNotFilled' : 'jsmb-annonce-pic-isNotFilled red' : 'jsmb-annonce-pic-isFilled'">
              <figure class="image">
                <img :src="stepThree.file_3.view">
              </figure>
              
              <b-field class="file">
                <b-upload drag-drop v-model="stepThree.file_3.object" @input="btnUpload('file-3')">
                  <a class="button is-small is-primary">
                    <span>Choisir un fichier</span>
                  </a>
                </b-upload>
                <a class="button is-danger" v-if="!hideForModal && stepThree.file_3.btnRemove" @click="removePiture('file_3')"><span>Supprimer</span></a>
              </b-field>
            </div>
            <p class="help has-text-weight-bold has-text-danger has-text-centered has-margin-bottom-1" :class="{'is-hidden': notError.file_3}">{{errorMsg.file_3}}</p>
          </div>

          <!-- <div class="column is-half-mobile has-text-centered jmsb-annonce-mobile">
            <figure class="image">
              <img :src="stepThree.file_4.view">
            </figure>
            <p class="help is-danger has-text-centered has-margin-bottom-1" :class="{'is-hidden': notError.file_4}">{{errorMsg.file_4}}</p>
            <b-field class="file">
              <b-upload drag-drop v-model="stepThree.file_4.object" @input="btnUpload('file-4')">
                <a class="button">
                  <span>Choisir un fichier</span>
                </a>
              </b-upload>
              <a class="button is-danger" v-if="!hideForModal && stepThree.file_4.btnRemove" @click="removePiture('file_4')"><span>Supprimer</span></a>
            </b-field>
          </div> -->
        </div>
      </div>

      <!-- BEGIN FOOTER 1 -->
      <div class="columns" v-if="hideForModal">
        <div class="column is-6 has-text-centered-mobile has-text-left-desktop">
          <button class="button is-danger-invert" @click="prevStep">Étape précédente</button>
        </div>
        <div class="column is-6 has-text-centered-mobile has-text-right-tablet">
          <button class="button is-danger" @click="nextStep">Étape suivante</button>
        </div>
      </div>

      <div class="level has-text-centered" v-else>
        <button class="button is-danger" type='button' @click="$parent.close()">Annuler</button>
        <button class="button is-primary" type='submit' @click="updatePicture" :class="buttonDoneLoad?'is-loading':''" :disabled="buttonDoneLoad?true:false">Valider</button>
      </div>

      <b-modal :active.sync="modalCrop">
        <vueCropper
          ref="cropper"
          :img="imgCrop"
          :autoCrop="true"
          :fixedNumber="['[128 : 85]']"
          :outputSize="0.8"
          :full="true"
          :fixedBox="true"
          :canMoveBox="false"
          mode="contain"
        ></vueCropper>
        <div class="column has-text-centered" style="margin:1rem;">
          <button type='button' class="button is-primary" style="margin-right:1rem;" v-on:click="$refs.cropper.changeScale(-1)"> - </button>
          <button type='button' class="button is-danger" v-on:click="cropProfilPicture">Valider</button>
          <button type='button' class="button is-primary" style="margin-left:1rem;" v-on:click="$refs.cropper.changeScale(1)"> + </button>
        </div>
      </b-modal>
      <b-modal :active.sync="modalWarningImage" has-modal-card class="jsmb-noPicture">
        <div class="modal-card">
          <header class="modal-card-head">
            <strong class="is-size-4">Une annonce avec photos est 7 fois plus consultée!</strong>
          </header>
          <section class="modal-card-body">
            <p>Multipliez par 10 vos chances de louer votre emplacement en mettant des photos de votre espace!</p>
            <p>Nous vous proposons de télécharger 3 photos:</p>
            <ul>
              <li>Une photo montrant l'accès à l'espace.</li>
              <li>Une photo montrant votre espace (terrain, garage, entrepôt...) donnera une bonne idée de sa qualité à vos locataires.</li>
              <li>Vous pouvez ajouter une autre photo de votre choix.</li>
            </ul>
          </section>
          <footer class="modal-card-foot " >
            <div class="columns jsmb-is-flex" style="width:100%">
              <div class="column is-6-desktop is-12-mobile has-text-centered second-order">
                <a class="button" type="button" @click="nextStepAfterModal()">Je valide mon annonce sans photo</a>
              </div>
              <div class="column is-6-desktop is-12-mobile has-text-centered jsmb-add-margin first-order">
                <a class="button is-danger" type="button" @click="modalWarningImage=false">Je rajoute des photos à mon annonce</a>
              </div>
            </div>
          </footer>
        </div>
      </b-modal>
    </div>
</template>
<script>
import { VueCropper }  from "vue-cropper"
import { UPDATE_PICTURE } from '@/store/modules/database/actions/database.actions'

export default {
  name: 'StepThree',
  components: {
    VueCropper
  },
  props: {
    hideForModal: {
      type:Boolean,
      default:true,
    },
    announcementId: {
      type:String,
      default: undefined,
    }
    ,
    pictures: {
      type:Object,
      default: undefined,
    }
  },
  data () {
    let noImage = require('./../../../assets/no-image.png')
    return {
      stepThree: {
        file_1: {
          object: null,
          view: noImage,
        },
        file_2: {
          object: null,
          view: noImage,
        },
        file_3: {
          object: null,
          view: noImage,
        },
        file_4: {
          object: null,
          view: noImage,
        },
      },
      modalWarningImage:false,
      modalCrop:false,
      imgCrop: null,
      notError: {
        file_1: true,
        file_2: true,
        file_3: true,
        file_4: true,
      },
      errorMsg: {
        file_1: '',
        file_2: '',
        file_3: '',
        file_4: '',
      },
      buttonDoneLoad: false,
    }
  },
  methods: {
    nextStep() {
      if (this.stepThree.file_1.object === null && this.stepThree.file_2.object === null && this.stepThree.file_3.object === null && this.stepThree.file_4.object === null) {
        // this.notError.file_1 = false
        // this.errorMsg.file_1 = "Veuillez envoyer au moins une photo pour booster votre annonce."
        this.modalWarningImage = true
      }else{
        this.nextStepAfterModal()
      }
    },
    nextStepAfterModal() {
      if (this.stepThree.file_1.object === null) {
        delete this.stepThree.file_1;
      }
      if (this.stepThree.file_2.object === null) {
        delete this.stepThree.file_2;
      }
      if (this.stepThree.file_3.object === null) {
        delete this.stepThree.file_3;
      }
      if (this.stepThree.file_4.object === null) {
        delete this.stepThree.file_4;
      }
      localStorage.setItem('stepThree', JSON.stringify(this.stepThree))
      this.$emit('nextStep');
    },
    prevStep() {
      this.$emit('prevStep');
    },
    btnUpload(buttonName) {
      let errorMsg = "Veuillez envoyer seulement des fichiers image."
      localStorage.setItem('btnUploadName', buttonName)
      switch (buttonName) {
        case 'file-1':
          this.notError.file_1 = this.uploadFile(this.stepThree.file_1)
          if (!this.notError.file_1) {
            this.errorMsg.file_1 = errorMsg
          }
          break;

        case 'file-2':
          this.notError.file_2 = this.uploadFile(this.stepThree.file_2)
          if (!this.notError.file_2) {
            this.errorMsg.file_2 = errorMsg
          }
          break;

        case 'file-3':
          this.notError.file_3 = this.uploadFile(this.stepThree.file_3)
          if (!this.notError.file_3) {
            this.errorMsg.file_3 = errorMsg
          }
          break;

        case 'file-4':
          this.notError.file_4 = this.uploadFile(this.stepThree.file_4)
          if (!this.notError.file_4) {
            this.errorMsg.file_4 = errorMsg
          }
          break;
      }
    },
    uploadFile(file) {
      if(window.FileReader) {
        var reader = new FileReader()
        if (file.object && file.object.type.match('image.*')) {
          reader.readAsDataURL(file.object)
          reader.onloadend = (e) => {
            this.modalCrop = true
            this.imgCrop = reader.result
          }
          return true
        } else {
          file.object = null
          file.view = require('./../../../assets/no-image.png')
          return false
        }
      }
    },
    cropProfilPicture() {
      this.$refs.cropper.getCropData((data) => {
        switch (localStorage.getItem('btnUploadName')) {
          case 'file-1':
            this.stepThree.file_1.view = data
            if (this.stepThree.file_1.id !== undefined) {
              this.stepThree.file_1.replace = true
              this.stepThree.file_1.remove = false
            }
            break;

          case 'file-2':
            this.stepThree.file_2.view = data
            if (this.stepThree.file_2.id !== undefined) {
              this.stepThree.file_2.replace = true
              this.stepThree.file_2.remove = false
              if (this.stepThree.file_2.id === null) {
                this.stepThree.file_2.btnRemove = true
              }
            }
            break;

          case 'file-3':
            this.stepThree.file_3.view = data
            if (this.stepThree.file_3.id !== undefined) {
              this.stepThree.file_3.replace = true
              this.stepThree.file_3.remove = false
              if (this.stepThree.file_3.id === null) {
                this.stepThree.file_3.btnRemove = true
              }
            }
            break;

          case 'file-4':
            this.stepThree.file_4.view = data
            if (this.stepThree.file_4.id !== undefined) {
              this.stepThree.file_4.replace = true
              this.stepThree.file_4.remove = false
              if (this.stepThree.file_4.id === null) {
                this.stepThree.file_4.btnRemove = true
              }
            }
            break;
        }
        this.modalCrop = false
        this.imgCrop = null
        localStorage.removeItem('btnUploadName')
      })
    },
    removePiture(index) {
      this.stepThree[index].replace = false
      this.stepThree[index].remove = true
      this.stepThree[index].btnRemove = false
      this.stepThree[index].object = null
      this.stepThree[index].view = require('./../../../assets/no-image.png')
    },
    updatePicture() {
      this.buttonDoneLoad = true
      this.$store.dispatch(
        UPDATE_PICTURE, { announcementId: this.$props.announcementId, pictures: this.stepThree }
      ).then(
        resp => {
          this.buttonDoneLoad = false
          this.$emit('add-update-picture', resp)
        }
      )
      
    }
  },
  created() {
    if (this.$props.pictures !== undefined) {
      this.stepThree = this.$props.pictures
    }else{
      if (localStorage.getItem('stepThree') !== null) {
        let stepThree = JSON.parse(localStorage.getItem('stepThree'));
        if (stepThree.file_1 !== undefined) {
          this.stepThree.file_1 = stepThree.file_1
        }
        if (stepThree.file_2 !== undefined) {
          this.stepThree.file_2 = stepThree.file_2
        }
        if (stepThree.file_3 !== undefined) {
          this.stepThree.file_3 = stepThree.file_3
        }
        if (stepThree.file_4 !== undefined) {
          this.stepThree.file_4 = stepThree.file_4
        }
      }
    }
  },
  // mounted() {
  //   this.stepThree = JSON.parse(localStorage.getItem('stepThree'))
  // }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="css">
  @media only screen and (max-width:768px) {
    .jsmb-add-margin {
      margin-bottom: 1rem;
    }
    .jsmb-is-flex {
      display: flex;
      flex-direction: column
    }
    .first-order {
      order: 1;
    }
    .second-order {
      order: 2;
    }
  }
  .vue-cropper {
    width: 100%;
    height: 0;
    padding-bottom: 66.40625%;
    margin: 0 auto;
  }
  .jsmb-annonce-padding {
    padding: 5vw 0;
  }
  h2 {
    font-weight: bold;
    margin-bottom: 3rem;
  }
  figure,
  h3,
  p {
    margin-bottom: 1rem;
  }
  .level {
    margin-top: 3rem;
  }
  .jsmb-annonce-photo .column {
    margin-right: 1rem;
  }
  .is-danger-invert {
    background-color: transparent;
    border-color: #df5051;
    color: #000000;
    margin-bottom: 1.5rem;
  }
  @media screen and (max-width:768px) {
    .jmsb-annonce-mobile {
      position:relative;
      left:50%;
      transform:translateX(-50%);
      margin-bottom: 1.5rem;
    }
  }
  .file {
    justify-content: center;
  }

  
  .jsmb-annonce-pic .column:not(:last-child) {
    margin-right: 1rem;
    margin-bottom: 1rem;
  }
  .jsmb-annonce-pic .column h3{
    margin-bottom: 0.5rem;
    font-weight: bold;
  }
  .jsmb-annonce-pic .column small {
    font-size: 0.7rem
  }
  .jsmb-annonce-pic-isFilled {
    border:1px solid #d3d3d3;
    padding:1rem;
  }
  .jsmb-annonce-pic-isNotFilled {
    border:5px dashed #d3d3d3;
    padding:1rem;
  }
  .jsmb-annonce-pic-isNotFilled.red {
    border:5px dashed rgba(223, 80, 81,0.9); 
  }
  .add-margin-bottom-1 {
    margin-bottom: 1rem;
  }
  
</style>

<style  lang="css">
  .jsmb-noPicture .modal-close.is-large {
    display: none
  }
  .jmsb-annonce-photo-container .upload .upload-draggable {
    border: 0px !important;
  }
  .jmsb-annonce-photo-container .has-addons {
    display:flex;
    flex-direction:column;
    align-items:center;
  }
  .jmsb-annonce-photo-container .file-name {
    border-width:0px;
    border-radius:3px;
    font-size:0.8rem;
    color:#fff;
    width:8rem;
    text-align: center;
    background:#14acc2;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .jmsb-annonce-photo-container .field.has-addons .control:first-child .button {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
  }

  .file .button {
    margin-bottom: 10px;
  }
</style>
