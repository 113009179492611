<template>
    <div>
      <h2 class="is-uppercase is-size-4" v-if="hideForModal">details</h2>
      <!-- BEGIN FORM -->
      <form @submit.prevent="submit">
        <!-- BEGIN SECTION 1 -->
        <div class="jsmb-type-option columns " v-if="hideForModal">
          <div class="column is-6">
            <h3 class="has-text-dark"><span class="is-uppercase has-text-weight-bold">sécurité</span> ( Choix multiples )</h3>
            <div class="columns" style="position:relative" v-if="isLoadingSecurities">
              <div class="column is-12" style="min-height:50px">
                <b-loading class="rmvBackground"  :is-full-page="false" :active.sync="isLoadingSecurities"></b-loading>
              </div>
            </div>
            <div class="columns is-multiline">
              <div class="column is-12" v-for="(security, securityIndex) in securities" :key="'security-'+securityIndex">
                <label class="checkbox">
                  <input type="checkbox" v-bind:id="security.name" v-bind:value="security.id" v-model="stepTwo.securities"/>
                  {{security.name}}
                </label>
              </div>
            </div>
          </div>
        </div>

        <!-- BEGIN SECTION 2 -->
        <div class="jsmb-type-espace"  v-for="(space, index) in stepTwo.spaces" :key="'space-'+index">
          <div class="inputArea">
            <h3 class="is-uppercase has-text-weight-bold has-text-dark">titre de la place <sup class="has-text-danger has-text-weight-bold">*</sup></h3>
            <div class="field">
              <div class="control">
                  <input class="input" type="text" placeholder="Saisir le titre de votre place" v-model="space.title" required>
                </div>
            </div>
            <div class="columns is-multiline is-vcentered">
              <div class="column is-6">
                <div class="control">
                  <span class="label is-size-5 is-uppercase has-text-dark has-text-weight-regular">Longueur</span>
                  <b-tooltip always :label="String(space.length + ' m')" position="is-right">
                    <input class="slider" v-model="space.length" v-bind:step="cursorStep" v-bind:min="cursorMin" v-bind:max="cursorMax" type="range"/>
                  </b-tooltip>
                </div>
              </div>
              <div class="column is-6">
                <div class="control">
                  <span class="label is-size-5 is-uppercase has-text-dark has-text-weight-regular">Hauteur</span>
                  <b-tooltip always :label="String(space.height + ' m')" position="is-right">
                    <input class="slider" v-model="space.height" v-bind:step="cursorStep" v-bind:min="cursorMin" v-bind:max="cursorMax" type="range"/>
                  </b-tooltip>
                </div>
              </div>
              <div class="column is-6">
                <div class="control">
                  <span class="label is-size-5 is-uppercase has-text-dark has-text-weight-regular">Largeur</span>
                  <b-tooltip always :label="String(space.width + ' m')" position="is-right">
                    <input class="slider" v-model="space.width" v-bind:step="cursorStep" v-bind:min="cursorMin" v-bind:max="cursorMax" type="range"/>
                  </b-tooltip>
                </div>
              </div>
              <div class="column is-6">
                <p class="has-text-weight-bold is-uppercase">Soit {{space.length*space.width}} m<sup>2</sup></p>
              </div>
            </div>

            <div class="columns is-multiline">
              <div class="column is-6">
                <h3 class="has-text-dark"><span class="is-uppercase has-text-weight-bold">Options </span><br>(Choix multiples /  5€/mois suplémentaires par option)</h3>
                <div class="columns" style="position:relative" v-if="isLoadingOptions">
                  <div class="column is-12" style="min-height:50px">
                    <b-loading class="rmvBackground"  :is-full-page="false" :active.sync="isLoadingOptions"></b-loading>
                  </div>
                </div>
                <div class="columns is-multiline">
                  <div class="column is-12"  v-for="(option, optionIndex) in options" :key="'option-'+index+optionIndex">
                    <label class="checkbox">
                      <input type="checkbox" v-bind:id="option.name" v-bind:value="option.id" v-model="space.options">
                      {{option.name}}
                    </label>
                  </div>
                </div>
              </div>
              <br>
              <div class="column is-6">
                <h3 class="has-text-dark"><span class="is-uppercase has-text-weight-bold">Niveau de difficulté d’accès<sup class="has-text-danger has-text-weight-bold">*</sup></span> <br>Choisissez entre Faible, Moyen ou Difficile</h3>
                <label class="radio" v-for="n in 3" :key="index+n">
                  <input type="radio" :name="'difficulty-'+index" v-bind:id="n" v-bind:value="n" v-model="space.difficulty" required/>
                </label>
              </div>
            </div>

            <br>

            <div class="columns is-multiline">
              <div class="column is-6">
                <h3 class="has-text-dark is-uppercase has-text-weight-bold">Type d’espace<sup class="has-text-danger has-text-weight-bold">*</sup></h3>
                <div class="columns" style="position:relative" v-if="isLoadingSpaceTypes">
                  <div class="column is-12" style="min-height:50px">
                    <b-loading class="rmvBackground"  :is-full-page="false" :active.sync="isLoadingSpaceTypes"></b-loading>
                  </div>
                </div>
                <div class="columns is-multiline">
                  <div class="column is-12" v-for="(spaceType, spaceTypeIndex) in spaceTypes" :key="'space-type-'+index+spaceTypeIndex">
                    <label class="radio" >
                      <input type="radio" :name="'space-type-'+index" v-bind:id="spaceType.name" v-bind:value="spaceType.id" v-model="space.spaceType" required/>
                      {{spaceType.name}}
                    </label>
                  </div>
                </div>
              </div>
              <br>
              <div class="column is-6" style="position:relative">
                <h3 class="has-text-dark is-uppercase has-text-weight-bold">type de location<sup class="has-text-danger has-text-weight-bold">*</sup></h3>
                <div class="columns" style="position:relative" v-if="isLoadingSpaceTypes">
                  <div class="column is-12" style="min-height:50px">
                    <b-loading class="rmvBackground"  :is-full-page="false" :active.sync="isLoadingLocationTypes"></b-loading>
                  </div>
                </div>
                <div class="columns">
                  <label class="radio" v-for="(locationType, locationTypeIndex) in locationTypes" :key="'location-type-'+index+locationTypeIndex">
                    <input type="radio" :name="'location-type-'+index" v-bind:id="locationType.name" v-bind:value="locationType.id" v-model="space.locationType" required/>
                    {{locationType.name}}
                  </label>
                </div>
              </div>
            </div>

            <div class="jsmb-type-option" v-if="!hideForModal">
              <h4 class="has-text-dark is-uppercase has-text-weight-bold">entrez votre tarif</h4>

              <div class="jmsb-place-container">
                <div class="columns jsmb-place is-vcentered">
                  <div class="column is-6 ">
                    <h4 class="is-size-5 has-text-weight-bold is-uppercase has-text-primary">
                      Prix de votre place
                    </h4>
                  </div>
                  <div class="column is-2">
                    <p class="place-input"><input type="number" min="0.01" step="0.01" v-model="space.priceUser" :name="'price-'+index" :id="'price-'+index" required  @change="showPrice"/></p>
                  </div>
                  <div class="column is-3">
                    <p class="place-input unit"><input type="number" min="0.01" step="0.01" v-model="space.price" :name="'price-'+index" :id="'price-'+index" disabled/></p>
                  </div>
                </div>
              </div>
            </div>

            <div class="has-text-right">
              <button class="button is-danger" type='button' @click="removeInput(index)" v-if="index >= 1">Enlever cette place</button>
            </div>
          </div>
        </div>

        <div class="level has-text-centered" v-if="hideForModal">
          <button class="button is-primary" type='button' @click="addInput">Ajouter une place</button>
        </div>

        <!-- BEGIN FOOTER 1 -->
        <div class="columns" v-if="hideForModal">
          <div class="column is-6 has-text-centered-mobile has-text-left-desktop">
            <button class="button is-danger-invert" type='button' @click="prevStep">Étape précédente</button>
          </div>
          <div class="column is-6 has-text-centered-mobile has-text-right-tablet">
            <button class="button is-danger" type='submit'>Étape suivante</button>
          </div>
        </div>

        <div class="level has-text-centered" v-else>
          <button class="button is-danger" type='button' @click="$parent.close()">Annuler</button>
          <button class="button is-primary" type='submit'>Valider</button>
        </div>
      </form>
    </div>
</template>

<script>
import { GET_LOCATION_TYPE, GET_OPTIONS, GET_SECURITIES, GET_SPACE_TYPE, GET_SETTING, UPDATE_SPACE, ADD_SPACE } from '@/store/modules/database/actions/database.actions'

export default {
  name: 'StepTwo',
  props: {
    hideForModal: {
      type:Boolean,
      default:true,
    },
    placeAction: {
      type:String,
      default:'',
    },
    place: {
      type:Object,
      default:[],
    }
  },
  data () {
    var cursorMin = 5;
    return {
      locationTypes: [],
      options: [],
      securities: [],
      spaceTypes: [],
      commissionPercentageJSMB: 0,

      isLoadingLocationTypes: false,
      isLoadingSecurities: false,
      isLoadingOptions: false,
      isLoadingSpaceTypes: false,

      counter: 0,
      cursorMin: cursorMin,
      cursorMax: 50,
      cursorStep: 1,

      stepTwo: {
        securities: [],
        spaces: [
          {
            title: 'Place n°1',
            length: cursorMin,
            height: cursorMin,
            width: cursorMin,
            options: [],
            difficulty: 0,
            spaceType: null,
            locationType: null,
            priceUser: 0,
            price: 0,
            priceDayRecommended: 0,
            priceMonthRecommended: 0,
          }
        ],
      },
    }
  },
  methods: {
    prevStep() {
      this.$emit('prevStep');
    },
    addInput() {
      this.stepTwo.spaces.push({
        title: 'Place n°'+(this.stepTwo.spaces.length+1),
        length: this.cursorMin,
        height: this.cursorMin,
        width: this.cursorMin,
        options: [],
        difficulty: 0,
        spaceType: null,
        locationType: null,
        priceUser: 0,
        price: 0,
        priceDayRecommended: 0,
        priceMonthRecommended: 0,
      });
    },
    removeInput(index) {
      this.stepTwo.spaces.splice(index, 1)
    },
    submit() {
      if (this.$props.hideForModal) {
        localStorage.setItem('stepTwo', JSON.stringify(this.stepTwo))
        this.$emit('nextStep')
      }else{
        switch (this.$props.placeAction) {
          case 'add':
            this.$store.dispatch(
              ADD_SPACE, { announcementId: this.$props.place.announcement_id, space: this.stepTwo.spaces }
            ).then(
              resp => {
                this.$emit('add-update-space', resp)
              }
            )
            break;
        
          case 'update':
            this.$store.dispatch(
              UPDATE_SPACE, { spaceId: this.$props.place.space_id, space: this.stepTwo.spaces }
            ).then(
              resp => {
                this.$emit('add-update-space', resp)
              }
            )
            break;
        }
      }
    },
    showPrice() {
      let valueCommissionJSMB = parseFloat(this.stepTwo.spaces[0].priceUser) * this.commissionPercentageJSMB / 100,
          price = Math.round((parseFloat(this.stepTwo.spaces[0].priceUser) + parseFloat(valueCommissionJSMB)) * 100) / 100

      this.stepTwo.spaces[0].price = price
    }
  },
  created() {
    this.isLoadingLocationTypes = true
    this.isLoadingSecurities = true
    this.isLoadingOptions = true
    this.isLoadingSpaceTypes = true

    // Get LocationTypes
    this.$store.dispatch(
      GET_LOCATION_TYPE
    ).then(
      resp => {
        this.isLoadingLocationTypes = false
        this.locationTypes = resp
      }
    )
    // Get Options
    this.$store.dispatch(
      GET_OPTIONS
    ).then(
      resp => {
        this.isLoadingOptions = false
        this.options = resp
      }
    )
    if (this.$props.hideForModal) {
      // Get Securities
      this.$store.dispatch(
        GET_SECURITIES
      ).then(
        resp => {
          this.isLoadingSecurities = false
          this.securities = resp
        }
      )
    }
    // Get SpaceTypes
    this.$store.dispatch(
      GET_SPACE_TYPE
    ).then(
      resp => {
        this.isLoadingSpaceTypes = false
        this.spaceTypes = resp
      }
    )
    if (localStorage.getItem('stepTwo') !== null) {
      this.stepTwo = JSON.parse(localStorage.getItem('stepTwo'))
    }

    // Get Settings
    this.$store.dispatch(
      GET_SETTING, {key: "commission_percentage_jsmb"}
    ).then(
      resp => {
        this.commissionPercentageJSMB = resp.value
      }
    )
    if (this.$props.place.length) {
      this.stepTwo.spaces = [
        {
          title: this.$props.place.space_title,
          length: this.$props.place.length,
          height: this.$props.place.height,
          width: this.$props.place.width,
          options: this.$props.place.options,
          difficulty: this.$props.place.difficulty,
          spaceType: this.$props.place.space_type_id,
          locationType: this.$props.place.location_type_id,
          priceUser: this.$props.place.price_per_day_user_ttc,
          price: this.$props.place.price_per_day_ttc,
        }
      ]
    }
  },
  mounted() {
    if(localStorage.getItem('stepTwo')) {
      this.stepTwo = JSON.parse(localStorage.getItem('stepTwo'))
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="css">
  h2 {
    font-weight: bold;
    margin-bottom: 3rem;
  }
  h3 {
    margin-bottom: 1rem;
  }
  input[type="radio"] {
    margin-bottom: 0;
  }
  input:not([type="checkbox"]),
  input:not('.slider'){
    margin-bottom: 0.5rem;
    border:none;
    background: transparent;
    border:1px solid #c7c9ce
  }
  textarea.textarea{
    background-color: transparent !important;
  }
  input[type=radio],
  input[type=checkbox] {
    border: 1px solid #df5051;
    padding: 0.5em;
    -webkit-appearance: none;
  }
  input[type=radio]:checked,
  input[type=checkbox]:checked {
    background-size: 9px 9px;
    background-color: #df5051;
  }
  input[type=radio]:focus,
  input[type=checkbox]:focus {
    outline-color: none;
  }
  .checkbox,
  .radio {
    font-size:0.85rem;
    margin-bottom: 0.5rem;
  }
  .is-horizontal-center {
    margin: 0 auto;
  }
  label {
    font-size: 1rem;
    font-weight: 100;
  }
  .stepOne-section-3 .control {
    margin: 1rem 0
  }
  .jsmb-type-espace .checkbox,
  .jsmb-type-location .checkbox {
    margin-right: 2rem;
  }
  .jsmb-type-acces .radio {
    margin-right: 1rem;
  }
  div[class^='jsmb-type']{
    margin-bottom: 3rem;
  }
  .jsmb-type-espace .control {
    padding-right: 2rem;
    margin-bottom: 2rem;
  }
  .jsmb-type-espace .control .label.is-size-5{
    margin-bottom: 2rem;
  }
  .jsmb-type-espace p {
    margin-top: 1.4rem;
    margin-bottom: 1.4rem;
  }
  .b-tooltip {
    display: flex;
  }
  .slider {
    -webkit-appearance: none;
    width: 85%;
    height: 10px;
    border-radius: 5px;
    background: #0ec0b6;
    outline: none;
    opacity: 1;
    -webkit-transition: .2s;
    transition: opacity .2s;
    padding: 0;
  }
  .slider::-webkit-slider-thumb {
    min-height: 20px;
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%; 
    background: #0ec0b6;
    border: 2px solid white;
    cursor: pointer;
  }
  .slider::-moz-range-thumb {
    min-height: 20px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #0ec0b6;
    cursor: pointer;
  }
  .slider::-ms-thumb {
    min-height:10px;
    width: 10px;
    height: 10px;
    border-radius: 50%; 
    background-color: #0ec0b6;
    border:2px solid #fff;
    cursor: pointer;
  } 
  .b-tooltip.is-right:after {
    left: calc(85% + 5px + 2px);
    margin-top: -5px;

  }
  .b-tooltip.is-right.is-primary:before {
    left: calc(85% + 2px);
    margin-top: -5px;
  }
  @media screen and (max-width: 768px) {
    .slider {
      width: 95%;
    }
    .b-tooltip.is-right:after {
      left: calc(95% + 5px + 2px);
    }
    .b-tooltip.is-right.is-primary:before {
      left: calc(95% + 2px);
    }
    .slider::-webkit-slider-thumb,
    .slider::-moz-range-thumb {
      width: 20px;
      height: 20px;
    }
  }
  .is-danger-invert {
    background-color: transparent;
    border-color: #df5051;
    color: #000000;
    margin-bottom: 1.5rem;
  }
  .level {
    margin-top: 3rem;
  }
  .jsmb-type-option {
      margin-top: 1rem;
  }
  .jmsb-place-container {
    margin-top: 1rem;
    margin-bottom: 2.5rem;
  }
  .jsmb-place {
    background:#F2F5FB;
    padding: 0rem 0.8rem;
    margin-bottom: 1rem;
  }
  .place-input {
    /* width: 5rem; */
    text-align: center;
    background-color: white;
    border: 1px solid #dbdbdb;
    color: #363636;
    /* width: -webkit-max-content;
    width: -moz-max-content; */
    width: max-content;
    border-radius: 4px;
    /* padding: 0.5rem 1.5rem; */
    position: relative;
  }
  .place-input input{
    padding: 0.5rem .5rem;
    width: 4rem;
    margin-bottom: 0;
  }
  .place-input.unit:after {
    content: "€ / jour";
    display: block;
    position: absolute;
    right: -3rem;
    top: 50%;
    /* -webkit-transform: translateY(-50%); */
    transform: translateY(-50%);
    font-size: 0.8rem;
  }
</style>
<style lang="css">
  .rmvBackground .loading-background {
    background: transparent !important
  }
</style>
